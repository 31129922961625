import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderResponse } from 'src/app/components/models/order';

@Injectable({
  providedIn: 'root'
})
export class ApiOrdersService {

  BaseUrl ='http://api.kintukyhouse2.apishipping.iraq-soft.info/'
  token :string;
  lang :string;
  constructor(private http: HttpClient) { }

  
  public getAllOrders():  Observable<OrderResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.get<OrderResponse>(this.BaseUrl+ 'admin/order/get' ,{headers});
  }


  public changeStatus(body ,id : number):  Observable<OrderResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<OrderResponse>(this.BaseUrl+ 'admin/order/change-status/'+ id ,body,{headers});
  }

}
